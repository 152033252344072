import { EventType, GTM } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'

import { PanelsDataProps } from './type'

const panelsClass = Bem('Panels')
const panelCardClass = Bem('PanelCard')
const MODULE_UID = 'module_panels'

export const Panels = ({
  title,
  panels,
  ctaButton,
  moduleLocation,
  className,
}: PanelsDataProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const { ctaButtonLink, ctaButtonText } = ctaButton ?? {
    ctaButtonLink: undefined,
    ctaButtonText: undefined,
  }
  const Count = panels.length
  return (
    <div data-module="panels" className={panelsClass(null, [className])}>
      <h3 className={panelsClass('Title')} data-testid="panelsTitle">
        {title}
      </h3>

      <div className={panelsClass('Wrapper')}>
        <div className={panelsClass('Cards', { Count })}>
          {panels.length > 0 &&
            panels.map(
              (
                {
                  panelTitle,
                  panelSubTitle,
                  panelLabel,
                  panelImageAltText,
                  panelButtonText,
                  panelButtonLink,
                  panelImage,
                },
                panelIndex,
              ) => (
                <div key={panelIndex} className={panelCardClass()}>
                  <div className={panelCardClass('Wrapper')}>
                    <div className={panelCardClass('Body')}>
                      <div className={panelCardClass('Header')}>
                        <Link
                          className={panelCardClass('ImageWrapper')}
                          to={panelButtonLink}
                          target={
                            panelButtonLink.startsWith('http')
                              ? '_blank'
                              : '_self'
                          }
                          trackGtmClick={trackGtmClick}
                        >
                          <Image
                            className={panelCardClass('Image')}
                            src={panelImage?.url}
                            alt={panelImageAltText}
                            fallback="brand"
                          />
                        </Link>
                      </div>
                      <div className={panelCardClass('Content')}>
                        <Link
                          className={panelCardClass('Title')}
                          to={panelButtonLink}
                          target={
                            panelButtonLink.startsWith('http')
                              ? '_blank'
                              : '_self'
                          }
                          trackGtmClick={trackGtmClick}
                          dangerouslySetInnerHTML={{
                            __html: renderMarkdown(panelTitle),
                          }}
                        />
                        <p
                          className={panelCardClass('SubTitle')}
                          dangerouslySetInnerHTML={{
                            __html: renderMarkdown(panelSubTitle),
                          }}
                        />
                        <p className={panelCardClass('Label')}>
                          {panelLabel.substring(0, 32)}
                        </p>
                      </div>
                    </div>
                    <div className={panelCardClass('Footer')}>
                      <Link
                        className={panelCardClass('ActionLink')}
                        data-testid="panelCardActionLink"
                        to={panelButtonLink}
                        target={
                          panelButtonLink.startsWith('http')
                            ? '_blank'
                            : '_self'
                        }
                        trackGtmClick={trackGtmClick}
                      >
                        {panelButtonText}
                      </Link>
                    </div>
                  </div>
                </div>
              ),
            )}
        </div>
      </div>

      {ctaButtonLink && ctaButtonText && (
        <Link
          className={panelsClass('ActionLink')}
          data-testid="panelsActionLink"
          to={ctaButtonLink}
          trackGtmClick={trackGtmClick}
          target={ctaButtonLink.startsWith('http') ? '_blank' : '_self'}
        >
          <span>{ctaButtonText}</span>
          <Icon icon="ChevronRight" className={panelsClass('LinkIcon')} />
        </Link>
      )}
    </div>
  )
}
