import { ContentListProps } from '@scm/ui-component-content-list'
import { ModuleLocationModularPages } from '@scm/tag-manager'

export interface ResourcesProps {
  title: string
  linkLists: Array<ContentListProps>
  variant: ResourcesVariant
  moduleLocation: ModuleLocationModularPages

  dateDisplay?: 'show' | 'hide'
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ResourcesVariant {
  article = 'article',
  homepage = 'homepage',
  programListPage = 'homepage',
}
