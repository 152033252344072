import { ContentList, ContentListProps } from '@scm/ui-component-content-list'
import { Bem } from '@scm/ui-core'

import { ResourcesProps, ResourcesVariant } from './type'

export const resources = Bem('Resources')

const MODULE_UID = 'module_resources'

export const Resources = ({
  title,
  linkLists,
  variant,
  moduleLocation,
  dateDisplay = 'show',
}: ResourcesProps) => {
  const isShowDate = dateDisplay === 'show'
  const linkListData = linkLists.filter(list => list.articles?.length)
  return (
    <>
      {linkListData.length ? (
        <div
          data-module="resources"
          className={resources({
            article: resourceVariant(variant) === 2,
          })}
        >
          <div
            className={resources('Header', {
              article: resourceVariant(variant) === 2,
            })}
          >
            <span className={resources('Title')} data-testid="resources-title">
              {title}
            </span>
          </div>
          <div className={resources('ContentWrapper')}>
            {linkListData.map((list: ContentListProps, index: number) => {
              if (index < resourceVariant(variant)) {
                return (
                  <div
                    className={resources('ItemWrapper', {
                      article: resourceVariant(variant) === 2,
                    })}
                    key={index}
                  >
                    <ContentList
                      {...list}
                      moduleUid={MODULE_UID}
                      moduleLocation={moduleLocation}
                      key={index}
                      isShowDate={isShowDate}
                    />
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      ) : null}
    </>
  )
}

export const resourceVariant = (variant: ResourcesVariant) => {
  const variantMap = new Map([
    [ResourcesVariant.article, 2],
    [ResourcesVariant.homepage, 3],
  ])

  return variantMap.get(variant) || 3
}
